import HomePage from "./components/home";
import Login from "./components/onBoarding/Login";

function App() {
  return (
    <>
      <HomePage />
    </>
  );
}

export default App;
