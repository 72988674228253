import React, { useState } from "react";

const initialState = [
  {
    title: "Noorani Masjid",
    address: "Near Anjuman College",
    namazName: ["Fazar", "Zuhar", "Asar", "Magrib", "Isha"],
    namazTiming: ["5:45", "1:45", "5:30", "7:10", "8:30"],
    lastUpdatedDate: "08/08/2024",
    direction: "direction",
  },
  {
    title: "Noorani Masjid",
    address: "Near Anjuman College",
    namazName: ["Fazar", "Zuhar", "Asar", "Magrib", "Isha"],
    namazTiming: ["5:45", "", "5:30", "", "8:30"],
    lastUpdatedDate: "08/08/2024",
    direction: "direction",
  },
];

const Card = () => {
  const [cardData] = useState(initialState);

  return (
    <div className="container mx-10">
      <div className="flex md:flex-row flex-col gap-4  ">
        {cardData.map((card, index) => (
          <div
            key={index}
            className="bg-white p-4 rounded-md shadow-md flex flex-col gap-4 md:w-1/2 w-full"
          >
            <div className="flex flex-col border-b-2 border-gray">
              <div className="text-xl font-bold">{card.title}</div>
              <p className="text-sm text-gray-700">{card.address}</p>
            </div>
            <div className="">
              <ul className="flex justify-between">
                {card.namazName.map((name, idx) => (
                  <li key={idx}>{name}</li>
                ))}
              </ul>
              <ul className="flex justify-between">
                {card.namazTiming.map((time, idx) => (
                  <li key={idx}>{time === "" ? "----" : time}</li>
                ))}
              </ul>
            </div>
            <div className="flex justify-between text-sm text-gray-700 border-t-2 border-gray">
              <p>Last updated: {card.lastUpdatedDate}</p>
              <p>{card.direction}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Card;
