import React from "react";
import { APP_NAME } from "../../utils/Constant";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../store/slices/userSlice";
import Card from "./components/Card";

const HomePage = () => {
  const { username } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  // console.log("data", username);
  const handleClicked = () => {
    let data = { id: 1, username: "Asif Ahmed" };
    dispatch(login(data));
  };

  return (
    <div className="">
      {/* <div>Welcome to {APP_NAME}</div> */}
      <Card />
      {/* {!username ? (
        <button
          className="bg-blue-500 px-3 py-1 my-1 rounded text-white text-xl "
          onClick={handleClicked}
        >
          please Login
        </button>
      ) : (
        username
      )} */}
    </div>
  );
};

export default HomePage;
